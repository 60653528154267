import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { Box, SimpleGrid, Heading } from "@chakra-ui/react"
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Layout from "../components/layout"
import SEO from "../components/seo"

function embeddedAsset(node) {
  return (
    <img
      style={{
        maxWidth: "100%",
      }}
      src={node?.data?.target?.fields?.file["en-US"]?.url}
      alt=""
    />
  )
}

const MemberPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const members = data.allContentfulFaggruppe?.edges
  const col1Members = members.filter(m => m.node?.kolonne === 1)
  const col2Members = members.filter(m => m.node?.kolonne === 2)
  const col3Members = members.filter(m => m.node?.kolonne === 3)
  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: node => embeddedAsset(node),
    },
  }

  return (
    <Layout location={location} title={siteTitle} container>
      <SEO title="Medlemmer" />

      <Heading size="xl" marginBottom="10">
        Medlemmer
      </Heading>
      <SimpleGrid columns={[1, 2, 3, 3]} spacing={10}>
        <Box>
          {col1Members
            .sort((a, b) => a.node.name - b.node.name)
            .map(member => {
              const mem = member.node
              const docContent = mem.medlemmer?.raw
                ? JSON.parse(mem.medlemmer?.raw)
                : null
              return (
                <Box key={mem.name} marginBottom="10">
                  <Heading marginBottom="3" size="md">
                    {mem.name}
                  </Heading>
                  {documentToReactComponents(docContent, options)}
                </Box>
              )
            })}
        </Box>
        <Box>
          {col2Members
            .sort((a, b) => a.node.name - b.node.name)
            .map(member => {
              const mem = member.node
              const docContent = mem.medlemmer?.raw
                ? JSON.parse(mem.medlemmer?.raw)
                : null
              return (
                <>
                  <Heading marginBottom="3" size="md">
                    {mem.name}
                  </Heading>
                  {documentToReactComponents(docContent, options)}
                </>
              )
            })}
        </Box>
        <Box>
          {col3Members
            .sort((a, b) => a.node.name - b.node.name)
            .map(member => {
              const mem = member.node
              const docContent = mem.medlemmer?.raw
                ? JSON.parse(mem.medlemmer?.raw)
                : null
              return (
                <>
                  <Heading marginBottom="3" size="md">
                    {mem.name}
                  </Heading>
                  {documentToReactComponents(docContent, options)}
                </>
              )
            })}
        </Box>
      </SimpleGrid>
    </Layout>
  )
}

MemberPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
}

export default MemberPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulFaggruppe {
      edges {
        node {
          name
          medlemmer {
            raw
          }
          kolonne
        }
      }
    }
  }
`
